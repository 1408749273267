
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_938apzhuxLUNMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue?macro=true";
import { default as airport_45hotels_45with_45parkingdKYa3PxcnTMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue?macro=true";
import { default as airport_45parkingC6HoaEMaP8Meta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue?macro=true";
import { default as airport_45shuttlesuLi9tMC2HEMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue?macro=true";
import { default as guidesB5aLZCOWMZMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue?macro=true";
import { default as off_45airport_45parkingy7OzHUbjG0Meta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue?macro=true";
import { default as parking_45couponsPIJ9oQfApGMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue?macro=true";
import { default as port_45hotels_45with_45parkingMR4zl2ivJxMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue?macro=true";
import { default as port_45parkingLDn4uoRibbMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue?macro=true";
import { default as _91result_939Gx6Eo5MfxMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue?macro=true";
import { default as healthXOlWy8pWe0Meta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93gEm72wSXruMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93IInZddEuQBMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93uFEYTy7b7kMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue?macro=true";
import { default as component_45stubhCaCY2lsKFMeta } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_rgd73p5parcdo22senhzkhkahq/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubhCaCY2lsKF } from "/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_rgd73p5parcdo22senhzkhkahq/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue")
  },
  {
    name: "IATA-airport-hotels-with-parking___en",
    path: "/:IATA()/airport-hotels-with-parking",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue")
  },
  {
    name: "IATA-airport-parking___en",
    path: "/:IATA()/airport-parking",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue")
  },
  {
    name: "IATA-airport-shuttles___en",
    path: "/:IATA()/airport-shuttles",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue")
  },
  {
    name: "IATA-guides___en",
    path: "/:IATA()/guides",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: "IATA-off-airport-parking___en",
    path: "/:IATA()/off-airport-parking",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue")
  },
  {
    name: "IATA-parking-coupons___en",
    path: "/:IATA()/parking-coupons",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue")
  },
  {
    name: "IATA-port-hotels-with-parking___en",
    path: "/:IATA()/port-hotels-with-parking",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue")
  },
  {
    name: "IATA-port-parking___en",
    path: "/:IATA()/port-parking",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue")
  },
  {
    name: "flight-status-result___en",
    path: "/flight-status/:result()",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue")
  },
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue")
  },
  {
    name: "hotel-package-code-name-IATA___en",
    path: "/hotel-package/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue")
  },
  {
    name: "hro-code-name-IATA___en",
    path: "/hro/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue")
  },
  {
    name: "parking-code-name-IATA___en",
    path: "/parking/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue")
  },
  {
    name: "Guides___en",
    path: "/:IATA([a-z]{3})",
    component: () => import("/codebuild/output/src1941815949/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: component_45stubhCaCY2lsKFMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubhCaCY2lsKF
  }
]